import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Card,
  CardMedia,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { firebaseService } from "../services/firebaseService"; // Ensure the correct path

const MealPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [meal, setMeal] = useState(null);

  useEffect(() => {
    const fetchMeal = async () => {
      const mealData = await firebaseService.getMealById(id);
      if (mealData) {
        setMeal(mealData);
      } else {
        navigate("/"); // If meal data is not available, navigate to the home page
      }
    };

    if (!meal) {
      fetchMeal();
    }
  }, [id, navigate]);

  if (!meal) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, bgcolor: "white", p: 3 }}>
        <Typography variant="h6" sx={{ color: "#555" }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, bgcolor: "white", p: 3 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIcon />}
        sx={{ mb: 2 }}
      >
        Back
      </Button>
      <Card sx={{ bgcolor: "white" }}>
        <CardMedia
          component="img"
          height="400"
          image={meal.img_link}
          alt={meal.title}
        />
        <CardContent>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{
              color: "#333",
              fontFamily: "Alfa Slab One",
              fontSize: "1.5em",
              fontWeight: "400",
            }}
          >
            {meal.title}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{ color: "#555" }}
          >
            <FastfoodIcon sx={{ mr: 1 }} />
            {meal.category || "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            <AccessTimeIcon sx={{ mr: 1 }} />
            Difficulty: {meal.difficulty || "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            <LocalDiningIcon sx={{ mr: 1 }} />
            Nutri-score: {meal.scores?.["Nutri-score"]?.split(" ")[1] || "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            <LocalDiningIcon sx={{ mr: 1 }} />
            Eco-score: {meal.scores?.["Eco-score"]?.split(" ")[1] || "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            Nutrition:
          </Typography>
          <List>
            {meal.nutrition
              ? Object.entries(meal.nutrition).map(([key, value]) => (
                  <ListItem key={key}>
                    <ListItemText
                      primary={`${key}: ${value}`}
                      sx={{ color: "#777" }}
                    />
                  </ListItem>
                ))
              : "N/A"}
          </List>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            Ingredients:
          </Typography>
          <List>
            {meal.ingredients
              ? meal.ingredients.map((ingredient, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={ingredient} sx={{ color: "#777" }} />
                  </ListItem>
                ))
              : "N/A"}
          </List>
          <Typography variant="body1" gutterBottom sx={{ color: "#777" }}>
            Steps:
          </Typography>
          <List>
            {meal.steps
              ? meal.steps.map((step, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${index + 1}. ${step}`}
                      sx={{ color: "#777" }}
                    />
                  </ListItem>
                ))
              : "N/A"}
          </List>
          <Typography variant="body1" gutterBottom>
            <IconButton
              component="a"
              href={meal.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#1e88e5" }}
            >
              <LinkIcon />
              View Full Recipe
            </IconButton>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MealPage;
