import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import HeartIcon from "@mui/icons-material/Favorite";
import ThumbsUpIcon from "@mui/icons-material/ThumbUp";
import QuestionMarkIcon from "@mui/icons-material/HelpOutline";
import "../styles/MatchCard.css"; // Import the CSS file

const MatchCard = ({ match, onUnlike, showUnlikeButton }) => {
  const navigate = useNavigate();
  const { mealId, name, imageUrl, ratings } = match;

  console.log("This is the data received by the match card", match);

  const handleCardClick = () => {
    if (mealId) {
      navigate(`/meal/${mealId}`);
    }
  };

  const getLikeTypeIcon = (ratingType) => {
    switch (ratingType) {
      case "Super like":
        return <StarIcon />;
      case "Like":
        return <HeartIcon />;
      case "Why not":
        return <ThumbsUpIcon />;
      default:
        return <QuestionMarkIcon />;
    }
  };

  const userRatings = ratings
    ? Object.keys(ratings).map((userId) => ({
        userId,
        icon: getLikeTypeIcon(ratings[userId]),
      }))
    : [];

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: 2,
        bgcolor: "#f5f5f5",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
      onClick={handleCardClick}
    >
      <Box sx={{ position: "relative" }}>
        {imageUrl && (
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={name}
            sx={{ objectFit: "cover" }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            display: "flex",
            gap: 0.1,
          }}
        >
          {userRatings.map(({ userId, icon }) => (
            <Box
              key={userId}
              sx={{
                bgcolor: "#FFAAAA",
                borderRadius: "50%",
                padding: 0.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
              }}
            >
              <IconButton size="small" sx={{ padding: 0 }}>
                {icon}
              </IconButton>
            </Box>
          ))}
        </Box>
        {showUnlikeButton && (
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              bgcolor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "50%",
              padding: 0.5,
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onUnlike(mealId);
              }}
              size="small"
              color="error"
              sx={{ padding: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <CardContent
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontSize: 14,
            fontWeight: "bold",
            color: "#474973",
          }}
        >
          {name || "No title available"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MatchCard;
