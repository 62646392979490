import { db, auth } from "./firebaseConfig";
import {
  ref,
  push,
  set,
  get,
  query,
  orderByKey,
  limitToFirst,
  update,
  remove,
  startAfter,
  equalTo,
  orderByChild,
  getDatabase,
} from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";

// Function to prioritize meals based on user preferences
const prioritizeMeals = (meals, preferences) => {
  const { categories, nutriScores, ecoScores } = preferences;

  return meals.sort((a, b) => {
    let aPriority = 0;
    let bPriority = 0;

    // Category match
    if (categories.includes(a.category)) aPriority += 1;
    if (categories.includes(b.category)) bPriority += 1;

    // Nutri-score match
    if (
      a.scores &&
      a.scores["Nutri-score"] &&
      nutriScores.includes(a.scores["Nutri-score"].split(" ")[1])
    ) {
      aPriority += 1;
    }
    if (
      b.scores &&
      b.scores["Nutri-score"] &&
      nutriScores.includes(b.scores["Nutri-score"].split(" ")[1])
    ) {
      bPriority += 1;
    }

    // Eco-score match
    if (
      a.scores &&
      a.scores["Eco-score"] &&
      ecoScores.includes(a.scores["Eco-score"].split(" ")[1])
    ) {
      aPriority += 1;
    }
    if (
      b.scores &&
      b.scores["Eco-score"] &&
      ecoScores.includes(b.scores["Eco-score"].split(" ")[1])
    ) {
      bPriority += 1;
    }

    return bPriority - aPriority; // Higher priority meals first
  });
};

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getMeals = async (userId, setMeals, limit = 100) => {
  try {
    const mealsRef = ref(db, "meals");
    const mealsSnapshot = await get(mealsRef);
    if (!mealsSnapshot.exists()) {
      console.log("No more meals available");
      return null; // No more meals available
    }

    const mealsData = mealsSnapshot.val();
    console.log("Fetched meals data:", mealsData);
    const allMeals = Object.keys(mealsData).map((key) => ({
      id: key,
      ...mealsData[key],
    }));

    // Shuffle meals and take the first 100
    const shuffledMeals = shuffleArray(allMeals).slice(0, limit);

    const swipesRef = ref(db, `swipes/${userId}`);
    const swipesSnapshot = await get(swipesRef);

    let filteredMeals = shuffledMeals;
    if (swipesSnapshot.exists()) {
      const swipesData = swipesSnapshot.val();
      console.log("Fetched swipes data:", swipesData);
      const swipedMealIds = new Set(
        Object.values(swipesData).map((swipe) => swipe.mealId)
      );
      filteredMeals = shuffledMeals.filter(
        (meal) => !swipedMealIds.has(meal.id)
      );
    }

    const preferences = await getDietaryPreferences(userId);
    console.log("Fetched user preferences:", preferences);
    filteredMeals = prioritizeMeals(filteredMeals, preferences);

    if (filteredMeals.length > 0) {
      setMeals((prevMeals) => [...prevMeals, ...filteredMeals]);
      console.log("Meals to set:", filteredMeals);
      return filteredMeals[filteredMeals.length - 1].id; // Return last meal's key
    }
    console.log("No more meals after filtering");
    return null; // No more meals available
  } catch (error) {
    console.error("Error fetching meals:", error);
    return null;
  }
};

const getMatches = async (setSuperMatches, setRegularMatches, userId) => {
  try {
    const matchesRef = ref(db, "matches");
    const snapshot = await get(matchesRef);

    if (!snapshot.exists()) {
      console.log("No matches available");
      setSuperMatches([]);
      setRegularMatches([]);
      return;
    }

    const matchesData = snapshot.val();
    const userMatches = Object.keys(matchesData)
      .map((key) => ({
        id: key,
        ...matchesData[key],
      }))
      .filter((match) => match.users.includes(userId));

    console.log("User matches:", userMatches);

    const superMatches = [];
    const regularMatches = [];

    userMatches.forEach((match) => {
      if (match.isSuperMatch) {
        superMatches.push(match);
      } else {
        regularMatches.push(match);
      }
    });

    console.log("Super matches:", superMatches);
    console.log("Regular matches:", regularMatches);

    if (typeof setSuperMatches === "function") {
      setSuperMatches(superMatches);
    } else {
      console.error("setSuperMatches is not a function");
    }

    if (typeof setRegularMatches === "function") {
      setRegularMatches(regularMatches);
    } else {
      console.error("setRegularMatches is not a function");
    }
  } catch (error) {
    console.error("Error fetching matches:", error);
    if (typeof setSuperMatches === "function") {
      setSuperMatches([]);
    }
    if (typeof setRegularMatches === "function") {
      setRegularMatches([]);
    }
  }
};

// Function to record a rating for a meal
const recordRating = async (userId, mealId, ratingType) => {
  const swipeRef = ref(db, `swipes/${userId}`);
  const newSwipeRef = push(swipeRef);
  await set(newSwipeRef, { mealId, ratingType, isMatched: false });

  if (ratingType === "Dislike") return null; // Exit if user dislikes the meal

  // Retrieve user data to get paired user IDs
  const userRef = ref(db, `users/${userId}`);
  const userSnapshot = await get(userRef);
  if (!userSnapshot.exists()) return null;

  const userData = userSnapshot.val();
  const pairedUserIds = userData.pairedUserIds || [];

  let match = null;

  // Iterate through each paired user ID to check for potential matches
  for (const pairedUserId of pairedUserIds) {
    const pairedUserSwipesRef = ref(db, `swipes/${pairedUserId}`);
    const pairedUserSwipesSnapshot = await get(pairedUserSwipesRef);

    if (pairedUserSwipesSnapshot.exists()) {
      const pairedUserSwipes = pairedUserSwipesSnapshot.val();

      // Check each swipe of the paired user for a match
      for (const swipeKey in pairedUserSwipes) {
        const swipe = pairedUserSwipes[swipeKey];
        if (
          swipe.mealId === mealId &&
          swipe.ratingType !== "Dislike" &&
          ["Why not", "Like", "Best"].includes(swipe.ratingType)
        ) {
          // Determine if it's a supermatch
          const isSuperMatch = swipe.ratingType === ratingType;

          // Fetch meal details for the match
          const mealRef = ref(db, `meals/${mealId}`);
          const mealSnapshot = await get(mealRef);
          if (!mealSnapshot.exists()) continue;
          const mealData = mealSnapshot.val();

          // Create a match entry
          const matchesRef = ref(db, "matches");
          const newMatchRef = push(matchesRef);
          await set(newMatchRef, {
            mealId: mealId,
            users: [userId, pairedUserId],
            name: mealData.title,
            imageUrl: mealData.img_link,
            timestamp: Date.now(),
            ratings: {
              [userId]: ratingType,
              [pairedUserId]: swipe.ratingType,
            },
            isSuperMatch: isSuperMatch,
          });

          // Update the swipes to indicate they resulted in a match
          await update(ref(db, `swipes/${userId}/${newSwipeRef.key}`), {
            isMatched: true,
          });
          await update(ref(db, `swipes/${pairedUserId}/${swipeKey}`), {
            isMatched: true,
          });

          match = {
            id: newMatchRef.key,
            mealId: mealId,
            isSuperMatch: isSuperMatch,
          };
          break;
        }
      }
    }
    if (match) break; // Exit loop if a match is found
  }

  return match;
};

// Function to change the rating type for a meal
const changeRatingType = async (userId, mealId, newRatingType) => {
  try {
    const swipeRef = ref(db, `swipes/${userId}`);
    const swipesQuery = query(
      swipeRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const swipesSnapshot = await get(swipesQuery);

    if (swipesSnapshot.exists()) {
      const swipeKey = Object.keys(swipesSnapshot.val())[0];
      await update(ref(db, `swipes/${userId}/${swipeKey}`), {
        ratingType: newRatingType,
      });
      console.log(`Updated rating for meal ${mealId} to ${newRatingType}`);

      // Update existing matches
      const matchesRef = ref(db, "matches");
      const matchesQuery = query(
        matchesRef,
        orderByChild("mealId"),
        equalTo(mealId)
      );
      const matchesSnapshot = await get(matchesQuery);

      if (matchesSnapshot.exists()) {
        matchesSnapshot.forEach(async (matchSnapshot) => {
          const match = matchSnapshot.val();
          if (match.users.includes(userId)) {
            const otherUserId = match.users.find((id) => id !== userId);
            const otherUserRating = match.ratings[otherUserId];
            const isSuperMatch = otherUserRating === newRatingType;

            await update(ref(db, `matches/${matchSnapshot.key}`), {
              [`ratings.${userId}`]: newRatingType,
              isSuperMatch: isSuperMatch,
            });
            console.log(
              `Updated match ${matchSnapshot.key} for meal ${mealId}`
            );
          }
        });
      }
    } else {
      console.log(`No swipe data found for meal ${mealId}`);
    }
  } catch (error) {
    console.error("Error changing rating type:", error);
  }
};

const getLikedMeals = async (userId, setLikedMeals) => {
  try {
    const swipesRef = ref(db, `swipes/${userId}`);
    const snapshot = await get(swipesRef);

    const likedMeals = [];
    if (snapshot.exists()) {
      const swipesData = snapshot.val();
      console.log("Fetched swipes data for liked meals:", swipesData);
      for (const swipeKey in swipesData) {
        const swipe = swipesData[swipeKey];
        // Exclude matched swipes
        if (
          swipe.ratingType &&
          swipe.ratingType !== "Dislike" &&
          !swipe.isMatched
        ) {
          const mealRef = ref(db, `meals/${swipe.mealId}`);
          const mealSnapshot = await get(mealRef);
          if (mealSnapshot.exists()) {
            const mealData = mealSnapshot.val();
            likedMeals.push({
              mealId: swipe.mealId,
              swipeId: swipeKey,
              title: mealData.title,
              category: mealData.category,
              img_link: mealData.img_link,
              ratingType: swipe.ratingType,
            });
          }
        }
      }
    }
    console.log("Liked meals to set:", likedMeals);
    setLikedMeals(likedMeals);
  } catch (error) {
    console.error("Error fetching liked meals:", error);
  }
};

// Function to unlike a meal
const unlikeMeal = async (userId, mealId) => {
  try {
    // Update swipes to set the ratingType to "Dislike"
    const swipesRef = ref(db, `swipes/${userId}`);
    const swipesQuery = query(
      swipesRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const swipesSnapshot = await get(swipesQuery);

    if (swipesSnapshot.exists()) {
      const swipeId = Object.keys(swipesSnapshot.val())[0];
      await update(ref(db, `swipes/${userId}/${swipeId}`), {
        ratingType: "Dislike",
      });
    } else {
      console.log("No swipe data available to update");
    }

    // Remove from matches
    const matchesRef = ref(db, "matches");
    const matchesQuery = query(
      matchesRef,
      orderByChild("mealId"),
      equalTo(mealId)
    );
    const matchesSnapshot = await get(matchesQuery);

    if (matchesSnapshot.exists()) {
      matchesSnapshot.forEach(async (matchSnapshot) => {
        const match = matchSnapshot.val();
        if (match.users.includes(userId)) {
          // Remove for both users
          await remove(ref(db, `matches/${matchSnapshot.key}`));
          const otherUserId = match.users.find((id) => id !== userId);
          const otherUserSwipesRef = ref(db, `swipes/${otherUserId}`);
          const otherUserSwipesQuery = query(
            otherUserSwipesRef,
            orderByChild("mealId"),
            equalTo(mealId)
          );
          const otherUserSwipesSnapshot = await get(otherUserSwipesQuery);

          if (otherUserSwipesSnapshot.exists()) {
            const otherUserSwipeId = Object.keys(
              otherUserSwipesSnapshot.val()
            )[0];
            await update(ref(db, `swipes/${otherUserId}/${otherUserSwipeId}`), {
              ratingType: "Dislike",
            });
          }
        }
      });
    } else {
      console.log("No match data available to remove");
    }
    return true;
  } catch (error) {
    console.error("Error unliking meal: ", error);
    return false;
  }
};

const getMealById = async (mealId) => {
  try {
    const mealRef = ref(db, `meals/${mealId}`);
    const mealSnapshot = await get(mealRef);
    if (mealSnapshot.exists()) {
      const mealData = mealSnapshot.val();
      console.log(mealData);
      return { id: mealId, ...mealData };
    } else {
      console.log("No meal data found for ID by getMealById:", mealId);
      return null;
    }
  } catch (error) {
    console.error("Error fetching meal by ID:", error);
    return null;
  }
};

// in firebaseService.js or a similar service file
const getMatchesWithMealDetails = async () => {
  const matchesRef = ref(db, "matches");
  const snapshot = await get(matchesRef);
  if (!snapshot.exists()) {
    console.log("No matches available");
    return [];
  }

  const matchesData = snapshot.val();
  const matchesWithDetails = await Promise.all(
    Object.keys(matchesData).map(async (key) => {
      const match = matchesData[key];
      const mealRef = ref(db, `meals/${match.mealId}`);
      const mealSnapshot = await get(mealRef);
      if (mealSnapshot.exists()) {
        const mealData = mealSnapshot.val();
        return {
          ...match,
          mealDetails: mealData,
        };
      } else {
        console.error(`No meal data found for ID: ${match.mealId}`);
        return match; // return match without meal details if not found
      }
    })
  );

  return matchesWithDetails;
};

// Function to get dietary preferences (already defined above)

const getDietaryPreferences = async (userId) => {
  try {
    const userRef = ref(db, `users/${userId}/preferences`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return {
        categories: [],
        nutriScores: [],
        ecoScores: [],
      };
    }
  } catch (error) {
    console.error("Error fetching dietary preferences: ", error);
    return {
      categories: [],
      nutriScores: [],
      ecoScores: [],
    };
  }
};

// Function to save dietary preferences (already defined above)

const saveDietaryPreferences = async (userId, preferences) => {
  try {
    const userRef = ref(db, `users/${userId}/preferences`);
    await update(userRef, preferences);
    return true;
  } catch (error) {
    console.error("Error saving dietary preferences: ", error);
    return false;
  }
};

export const firebaseService = {
  getMeals,
  getMealById,
  getMatches,
  recordRating,
  getLikedMeals,
  unlikeMeal,
  changeRatingType,
  getDietaryPreferences,
  saveDietaryPreferences,
  getMatchesWithMealDetails,
};
