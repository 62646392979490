import React, { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import MatchCard from "../components/MatchCard";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import NoMatchesImage from "../assets/no_matches_image.png";

import { ReactComponent as HeartIcon } from "../assets/favorite-icon.svg";
import { ReactComponent as StarIcon } from "../assets/star-icon.svg";

import "../styles/MatchesPage.css"; // Import the CSS file

const MatchesPage = () => {
  const [superMatches, setSuperMatches] = useState([]);
  const [regularMatches, setRegularMatches] = useState([]);
  const [filter, setFilter] = useState("superMatches");

  useEffect(() => {
    const userId = auth.currentUser.uid;
    const fetchMatches = async () => {
      try {
        await firebaseService.getMatches(
          setSuperMatches,
          setRegularMatches,
          userId
        );
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };
    fetchMatches();
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleUnlike = async (mealId) => {
    const success = await firebaseService.unlikeMeal(
      auth.currentUser.uid,
      mealId
    );
    if (success) {
      setSuperMatches((prevMatches) =>
        prevMatches.filter((match) => match.mealId !== mealId)
      );
      setRegularMatches((prevMatches) =>
        prevMatches.filter((match) => match.mealId !== mealId)
      );
    } else {
      console.error("Failed to unmatch meal");
    }
  };

  const renderMatches = (matches) => (
    <>
      {matches.length > 0 ? (
        <div className="grid-container">
          {matches.map((match) => (
            <div className="grid-item" key={match.mealId}>
              <MatchCard
                match={match}
                onUnlike={() => handleUnlike(match.mealId)}
                showUnlikeButton={true}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="no-matches">
          <SentimentDissatisfiedIcon className="no-matches-icon" />
          <div className="no-matches-text">No matches here yet...</div>
          <img
            src={NoMatchesImage}
            alt="No matches"
            className="no-matches-image"
          />
        </div>
      )}
    </>
  );

  return (
    <div className="matches-page">
      <TopBar title="Matches" />
      <div className="toggle-button-group">
        <button
          className={`toggle-button ${
            filter === "superMatches" ? "active" : ""
          }`}
          onClick={() => handleFilterChange("superMatches")}
        >
          <StarIcon className="toggle-icon" />
        </button>
        <button
          className={`toggle-button ${
            filter === "regularMatches" ? "active" : ""
          }`}
          onClick={() => handleFilterChange("regularMatches")}
        >
          <HeartIcon className="toggle-icon" />
        </button>
      </div>

      {filter === "superMatches"
        ? renderMatches(superMatches)
        : renderMatches(regularMatches)}
    </div>
  );
};

export default MatchesPage;
