import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faHeart,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathToIndex = {
    "/": 0,
    "/matches": 1,
    "/liked-meals": 2,
    "/settings": 3,
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "#f5f5f5",
      }} // White smoke
    >
      <BottomNavigation
        value={pathToIndex[location.pathname]}
        onChange={(event, newValue) => {
          navigate(Object.keys(pathToIndex)[newValue]);
        }}
        showLabels
        sx={{
          bgcolor: "#f5f5f5", // White smoke
          "& .MuiBottomNavigationAction-root": {
            color: "#474973", // Ultra Violet
            "&.Mui-selected": {
              color: "#f45f67", // Bright pink (Crayola)
            },
          },
        }}
      >
        <BottomNavigationAction
          label="Carte"
          icon={<FontAwesomeIcon icon={faUtensils} />}
        />
        <BottomNavigationAction
          label="Matches"
          icon={<FontAwesomeIcon icon={faHeart} />}
        />
        <BottomNavigationAction
          label="Likes"
          icon={<FontAwesomeIcon icon={faThumbsUp} />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default NavBar;
