import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUtensils,
  faHeart,
  faUser,
  faCog,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

import NavBar from "./components/NavBar";

import SwipePage from "./pages/SwipePage";
import MatchesPage from "./pages/MatchesPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SettingsPage from "./pages/SettingsPage";
import PairingManagementPage from "./pages/PairingManagementPage";
import LikedMealsPage from "./pages/LikedMealsPage";
import DietaryPreferencesPage from "./pages/DietaryPreferencesPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ProfilePage from "./pages/ProfilePage";
import MealPage from "./pages/MealPage";

import { SwipeProvider } from "./contexts/SwipeContext";
import { LikedMealsProvider } from "./contexts/LikedMealsContext";
import { MatchesProvider } from "./contexts/MatchesContext";

// Add icons to the library
library.add(faUtensils, faHeart, faUser, faCog, faThumbsUp);

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1DB954",
    },
    secondary: {
      main: "#1ED760",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: "white", minHeight: "100vh", width: "100%" }}>
        <Router>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100%",
            }}
          >
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                pb: 7,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route element={<ProtectedRoute />}>
                  <Route
                    path="/*"
                    element={
                      <SwipeProvider>
                        <LikedMealsProvider>
                          <MatchesProvider>
                            <Routes>
                              <Route path="/" element={<SwipePage />} />
                              <Route
                                path="/matches"
                                element={<MatchesPage />}
                              />
                              <Route
                                path="/liked-meals"
                                element={<LikedMealsPage />}
                              />
                              <Route
                                path="/settings"
                                element={<SettingsPage />}
                              />
                              <Route
                                path="/profile"
                                element={<ProfilePage />}
                              />
                              <Route
                                path="/dietary-preferences"
                                element={<DietaryPreferencesPage />}
                              />
                              <Route
                                path="/pairing-management"
                                element={<PairingManagementPage />}
                              />
                              <Route path="/meal/:id" element={<MealPage />} />
                            </Routes>
                          </MatchesProvider>
                        </LikedMealsProvider>
                      </SwipeProvider>
                    }
                  />
                </Route>
              </Routes>
            </Box>
            <NavBar />
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
